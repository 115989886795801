
/* about */

@media (max-width: 800px) {

    div.team-row{
        flex-direction: column;
     }
}

div.about {
    display: flex;
 width:100%;
    flex-direction: column;
    align-items: center;
}

div.about div.card img{
    border-radius: 10px;
}


div.team-row{
    display: flex;
    width: 85%;
    margin-top: 20px;
    margin-bottom: 20px;
 }

