 
@media (max-width: 800px) {
    div.main div.features-row{
        flex-direction: column;
        width:auto !important;
    }

    div.landingPage .text {
        margin:0px !important;
      }

    div.landingPage .background{
      height:100% !important;
    }

    div.landingPage {
      height:auto !important;
    }

 .landingPage-about {
    width: 100% !important; 
}
}

@media (min-width: 900px) {
    div.landingPage div.hero img {
       
    }
}

div.main{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Landing Page */

div.landingPage{
    display: flex;
    align-items: center;
    height: calc(100dvh - 101px);
    width:100%;
    overflow: hidden;
    justify-content: center;
    color: black;
    padding-bottom: 30px;
    /* background: linear-gradient(to bottom, var(--secondary-color), var(--primary-dark),var(--primary-color)); */
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(2em);
}

div.landingPage div.text-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 650px;
    margin-left: 40px;
    margin-right: 40px;
}

div.main div.landingPage .hero{
    width:85%;
}

div.landingPage .background{
    position: absolute;
    background-color: var(--primary-color);
    height: calc(100dvh - 101px);
    width: 100%;
    z-index:-1;
    -webkit-filter: blur(2px);
    filter: blur(2px);
}

/* 2 Features */

div.main .main-features{
    display:flex;
    flex-direction: column;
    align-items: center;
}

div.main div.features-row{
    display: flex;
    width: 70%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
 }

 div.main .link a{
    display: flex;
    justify-content: center;
    font-size: larger;
    color: var(--primary-dark) ;
 }

 div.dark div.main .link a{
    color: var(--primary-light) ;
 }

 .landingPage-about{
    width: 85%;
 }
 