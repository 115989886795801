
/* card */

@media (max-width: 800px) {

    div.card {
        width: initial !important;
    }
   
    div.card img{
        display: flex;
        /* justify-content: center; */

        min-width: 300px !important;
  
     }
    div.text-area .text{
    min-width: auto !important;
    }
}

div.card {
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 33.3%;
    margin: 20px
}

div.card i{
    font-size: 4em;
    color: var(--primary-color);
}

div.dark div.card i{
    color: var(--primary-light);
}


div.card img{
    display: flex;
    /* justify-content: center; */
    width: 95%;
    height: auto;
    min-width: 100px;
    max-width: 200px;
    margin-right: 10px;
    margin-left: 10px;
 }

div.text-area{
    display: flex;
 
    flex-direction: column;
    justify-content: center;
   ;
}

div.card div.text-area .title{
    display: flex;
    align-items: center;
    color: var(--primary-text) ;
    text-align: center;
    justify-content: center;
    margin: 10px;
    height: 50%;
    font-weight: 500;
    
}



div.text-area .text{
    display: flex;
    flex-wrap: wrap;
    
    white-space: pre-line;
    min-width: 100%;
    margin: 10px;
    height: 50%;
}

div.text-area .link{
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
}

