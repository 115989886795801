@media (max-width: 640px) {
  footer{
    flex-direction: column ;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    
    justify-content: space-evenly !important;
  }
  
}

.footer-brand{
  display: flex;
  font-weight:bold !important;
  font-size: 18px;
  color: var(--primary-color);
  text-decoration: none;
  white-space: nowrap;
}

ul.footer-links {
  list-style: none;
  display: flex;
  padding: 0;
  margin-block-start: 0px;
  margin-block-end: 0;
  
}

footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;

  padding-left: 4em;
  padding-right: 4em;
  bottom:0;
  z-index:2;
}

footer li.nav-item {
  padding-left: 5px;
  padding-right: 5px;
  
    text-align: center;
  align-self: center;
}

div.dark footer li.nav-item a{
  color: var(--primary-light);
}

a:hover {
  text-decoration: underline;
  
}
 
