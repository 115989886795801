/* Account */

@media (max-width: 980px) {
   div.home div.row{
     flex-direction: column;;
   }

    div.account div.main-card-actions{
        flex-direction: column;
    }

    div.account .MuiButtonBase-root{
        width:100%;
    }

    div.account div.account-main-card {
        padding-left: 5px !important;
        padding-right: 5px !important;
        padding-top: 0px !important;
        padding-bottom: 20px !important;
    }

    div.account .account-form {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    div.account div.row {
        margin:10px !important;
    }
    
}




div.home div.account{
    display: flex;
    flex: 5;
    flex-direction: column;;
    background-color: var(--secondary-color);
    border-radius: 20px;
 
    margin: 5px;
    min-width: 0;
}

div.dark div.home div.account{
    background-color: black;
}

div.account .page-title{
    margin: 20px;
    color: white;
}

div.account div.row{
    display: flex;
    gap: 20px;
    margin: 20px;
    justify-content: center;
}

div.account div.infocard-row{
    display: flex;
    gap: 20px;
    margin: 5px;
    width: 100%;
    justify-content: center;
}


div.account div.account-main-card{
    display: flex;
    flex: 2;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
  
    padding: 30px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

div.account .account-form{
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-left: 20px;
}


div.account div.main-card-actions{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
}

div.dark div.account .MuiFormLabel-root{
    color: black !important;
    z-index: 0;
}

div.account .account-form-inputs{
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    
}

div.account .account-form-input-row{
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.account .account-form-input-row-inputanderror{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 2px;
}



div.account div.account-info-card{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;

    padding: 20px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

div.account .info-wrap{
    display: flex;
    align-items: center;;
    flex-direction: column;
}

div.dark div.account .info-wrap{
    color: black;;
}


div.account .info-label{
    font-weight: bold;
    white-space: nowrap;
    margin: 5px;
}

div.account .info{
    display:flex;
    justify-content:center;
    margin: 5px;
    font-weight: 400;
    color: var(--secondary-text);
}

div.account .infocard-language-label{
    display: flex;
    align-items: center;
}

div.account .infocard-app-settings{
    /* align-self: flex-start; */
    margin-top: 10px;
    /* padding-left: 30px;  */
}


/***************** AVATAR ******************/

div.home img.avatar{
       display: flex;
    object-fit: cover;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: 15px;
    object-fit: cover;
    background-size: cover;
    align-self: center;
}

div.home div.avatar-container {
    display:flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    position:relative;
    cursor: pointer;
}

.wrap-text{
    display:flex;
    align-self:center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    background-color: var(--secondary-color);
    color: white;
    margin:15px;
    font-size: smaller;
    transition: 0.3s;
}

div.home div.avatar-container:hover .wrap-text{
    visibility: visible;
    transition: 0.3s;
    opacity: 0.8;
}

.bi-pencil-square{
    width: 100%;
    font-size: large;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0%;
    margin: 0px;
}

.upload-progress{
    display: flex;
    align-self: center;
    position:absolute;
    background-color: var(--secondary-text);
    color: white;
    border-radius: 6px;
    padding: 3px;
    font-size: smaller;
}






