/* Users */

@media (max-width: 700px) {
    div.users{
        
    }
    .delete-confirmation{
            width: 80% !important;
    }
    
}


div.users{
    display: flex;
    flex: 5;
    justify-content: center;
    flex-direction: column;;
   
    background-color: var(--secondary-color);
    border-radius: 20px;
    margin: 5px;
}

div.dark div.users{
    background-color: black;
}

div.users .page-title{
    margin: 20px;
    color: white;
}

div.users img.datagrid-mini-avatar{
    border-radius: 50%;
    width: 30px;
    height: 30px;
}
div.users div.datagrid-actions{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


div.users div.MuiDataGrid-cell--withRenderer{
    justify-content: center;
}

div.users-pannel{
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

div.users .skeleton-users-pannel{
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
    
}

div.users div.MuiDataGrid-root{
    border: none;
}

div.users .clickable-icon{
    cursor: pointer;
}

 .delete-confirmation{
    position: absolute;
    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    white-space:pre-line;
    border: 1px solid var(--primary-color);
    box-shadow: 0 2.5rem 2rem -2rem hsl(200 50% 20% / 40%);
    background-color: white;
    border-radius: 20px;
    padding: 20px;

    animation-name: zoom-in-appear;
    animation-duration: 0.3s;
}

@keyframes zoom-in-appear {
    from {
        transform: translate(-50%, -50%) scale(0);
    }
    to {
        transform: translate(-50%, -50%) scale(1);
    }
}
 .delete-confirmation-buttons{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    align-items: center;
}

    

