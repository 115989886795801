/* Dashboard */

@media (max-width: 980px) {
    div.dashboard .widget-row{
        flex-direction:column;
    }
    div.dashboard .widget-half-row{
        width: 100% !important;
    }
    
    div.dashboard div.widget{
        width:100% !important;
    }

    div.dashboard div.mini-widget{
        width:100% !important;
    }
    
}

div.home div.dashboard{
    display: flex;
    flex-direction: column;
    flex: 5;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: 20px;
    margin: 5px;
    padding: 20px;
    /* gap: 20px; */
}

div.home div.dashboard .page-title{
    margin: 5px;
    color: white;
    align-self: baseline;
}

div.dark div.home div.dashboard{
    background-color: black;
}

div.dark div.dashboard .page-title{
    color: white;
}

div.dashboard .widget-row{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

div.dashboard .widget-half-row{
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

div.dashboard .widget-row-data{
    display: flex;
    width: 100%;
}


div.dashboard div.widget{
    width: 100%;
    height:150px;
    background-color: white;
    border-radius: 30px;
    padding-bottom:50px;
   box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    
}

div.dashboard div.mini-widget{
    width: 100%;
    height:200px;
    background-color: white;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

 #total-users{
    cursor: pointer;
}

div.dashboard .data-widget{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

div.dashboard div.MuiDataGrid-root{
    border: none;
}

div.dashboard img.datagrid-mini-avatar{
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

div.dashboard .graph-wrapper{
    display: flex;
    width: 300px;
    margin: 10px;
    justify-content: center;
    align-items: center;
}

div.dashboard .mini-widget-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 22px;
    align-items: center;
}

div.dashboard .mini-widget-icon{
    display: flex;
    color: var(--primary-color);
    font-size: 50px;
    margin: 5px;
}

div.dashboard .mini-widget-title{
    display: flex;
    font-weight: bold;
    color: var(--primary-color);
}

#title {
    display: flex;
    font-weight: bold;
    color: var(--secondary-color);
}

.MuiTooltip-tooltip {
    background-color: var(--secondary-color) !important;
}

div.dashboard .mini-widget-data{
    display: flex;
    font-weight: bold;
    color: var(--primary-color);
    justify-content: center;
  
}

div.dashboard .widget-header{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

div.dashboard .widget-title{
    display: flex;
    color: var(--primary-color);
    margin-top: 20px;
    margin-left: 20px;
    
}
div.dashboard .widget-year{
    display: flex;
    font-weight: bold;
    color: var(--primary-color);
    margin-top: 20px;
    margin-right: 20px;
    
}

