/* Price Tracker */



@media (max-width: 770px) {

    .pricetracker-fullpage-wrapper{
        height: 100% !important;
        
    }
    .website{
        overflow-x: hidden;
    }
    .pricetracker-fullpage{
        flex-direction: column;
        margin-top: 0px !important;
        margin-bottom:0px !important
    }

    .fixed-navbar{
        position: relative !important;
        width: unset !important; 
    }
    .fixed-footer{
        position: relative !important;
    }
    .fixed-footer-brand{
        padding-left: 0px !important;
    }
    .fixed-footer-links{
        margin-left: 2em !important;
    }

    .pricetracker-inputcard{
        margin: 50px auto;
        order: 1;
        margin-left: auto !important;
        position: relative!important;
        width: 95%!important;
        
    }
    .pricetracker-mytrackers{
        margin-left: auto!important;
        margin-right: auto !important;
        margin-top: 30px!important;
        order: 2;
        width: 95%!important;
        margin-bottom: 0px !important
    }

    img.pricetracker-mytrackers-img {
        width: 80px;
    }

    .pricetracker-mytrackers-img-link {
        width: 75px !important;
    }

    div.pricetracker-fullpage .container {
        padding-left:10px !important;
    }

    div.pricetracker-fullpage .container .subscribe-bell {
        left:15px !important;
    }

    .pricetracker-mytrackers-info{
        
    }

    .pricetracker-mytrackers-title {
        
        line-height: 1.0 !important;
        letter-spacing: 0.00338em !important;
    }

    .pricetracker-mytrackers-price {
        padding-left: 5px !important;
        padding-right: 0px !important;
    }

     div.pricetracker-mytrackers-info .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
       
    }

    .pricetracker-mytrackers-delete{
        /* margin-left: 0 !important; */
    }

    .pricetracker-mytrackers-header{
        
        
    }
    
    .pricetracker-tracker{
        padding: 7px !important;
    }
    
    
}

/* navbar fixed only on pricetracker page */
.fixed-navbar{
    /* background-color: rgb(244, 211, 211); */
    position: fixed;
    width: calc(100% - 40px);
    backdrop-filter: blur(10px);
    background-color: unset !important;
    /* border-radius: 20px;
    top: 5px; */
}
.fixed-footer{
    position: fixed;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    background-color:unset !important;
    backdrop-filter: blur(10px);
    /* margin-top: 70px; */
}
.fixed-footer-brand{
    padding-left: 2em;
}

.fixed-footer-links{
    padding-right: 2em !important;
}

.pricetracker-fullpage-wrapper{
    height: 100vh;
    
}
.pricetracker-fullpage{
    display: flex;
    gap: 5%;
    justify-content: flex-end;
    /* margin-right: 5%; */
    /* justify-content: center; */
    margin-top: 100px;
    margin-bottom: 70px;
}

.pricetracker-fullpage-loading{
    display: flex;
    margin-bottom: 50px;
    justify-content: center;
}

.pricetracker-fullpage-loading-wrapper{
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

#modal-modal-title{
    font-weight: bold;
}



.pricetracker-inputcard{
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0px;
    row-gap: 20px;
    justify-content: center;
    height: 74%;
    margin-left: 50px;
    /* margin-right: 1%; */
    width: 40%;
}

.pricetracker-mytrackers{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    margin-bottom: 70px;
    width: 45%;
}

.pricetracker-mytrackers-header{
    margin-bottom: 30px !important;
}

.pricetracker-tracker{
    display: flex;
    overflow: hidden;
    
    margin-bottom: 5px;
    background: white;
    color: #000000;
    flex-direction: column;
}

div.dark .pricetracker-tracker{
    
    background: black;
    color: #ffffff;
}

.pricetracker-mytrackers-img-wrapper{
    transition: transform 0.2s ease-in-out;
}

.pricetracker-mytrackers-img-outofstock-container{
    position: relative;
    text-align: center;
    /* color: red ; */
}
/* .pricetracker-mytrackers-img-outofstock{
    position: absolute;
    
    text-decoration-style: solid;
    text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #fffefe;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.pricetracker-mytrackers-img-outofstock{
    position: absolute;
    width: 85px;
    /* border-radius: 5px 0px 5px 0px; */
    border-radius: 5px;
    /* border: 2px solid red; */
    background-color: white;
    /* backdrop-filter: blur(50px); */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-15deg);
}

.pricetracker-mytrackers-img-wrapper:hover{
    transform: translateY(-2px);
}


.pricetracker-mytrackers-info{
    display: flex;
    align-items: center;
        min-height: 100px;
    justify-content: space-between;
}

.pricetracker-mytrackers-info-skeleton{
    display: flex;
    height: fit-content;
}

.pricetracker-mytrackers-title{
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5em ;
    word-wrap: break-word;
    max-height: 2.5em;
    line-height: 0.98 !important;
}

.pricetracker-mytrackers-title-skeleton{
    margin-left: 20px;
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.pricetracker-mytrackers-info-title-text-skeleton{
    width: 100%;
}

.pricetracker-mytrackers-price{
    padding-top: 0.5em ;
    padding-bottom:0.5em;
    padding-left: 10px;
    padding-right: 10px;
}
.pricetracker-mytrackers-price-column-skeleton{
    display: flex;
    margin-left: 15px;
    display: flex;
    width: 10%;
    align-items: center;
}
.pricetracker-mytrackers-price-skeleton{
    width: 100%;
}

.pricetracker-mytrackers-graph{
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 100px;
    justify-content: space-between;
}

.pricetracker-mytrackers-graph-skeleton{
    width: 100%;
    margin-top: 5px;
    height: 100px !important;
}

.pricetracker-mytrackers-graph .recharts-surface{
    overflow: visible;
}

    

.pricetracker-mytrackers-img{
    display: block;
    max-width: 100px;
    max-height: 100px;
    width: auto;
    height: auto;
}

.pricetracker-mytrackers-info-img-skeleton{
    min-width: 100px;
    min-height: 80px;
}



.pricetracker-mytrackers-img-link{
    width: 100px;
    display: flex;
    justify-content: center;
}

/* .pricetracker-mytrackers-url{
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */


.priceTrackerMenu [class*="MuiMenu-list"] {
    padding-top: 0px;
    padding-bottom: 0px;
}


.pricetracker-mytrackers-delete{
    display: flex;
    cursor: pointer;
}

.pricetracker-mytrackers-delete-column-skeleton{
    display: flex;
    margin-left: 15px;
    display: flex;
    width: 5%;
    align-items: center;
}

.pricetracker-mytrackers-delete-skeleton{
    width: 100%;
    height: 25px;
}

/* delete confirmation dialog background */
#pricetracker-delete-dialog .MuiBackdrop-root{
    backdrop-filter: blur(5px);
}



.pricetracker-inputcard-submit{
    margin-top: 4px;
}

div.pricetracker-fullpage .container {
    position: relative;
    display: flex;
    padding-left: 20px;
    flex-grow: 1;
}

div.pricetracker-fullpage .container .subscribe-bell {
    position: absolute;
    filter: drop-shadow(1px 3px 1px rgba(202, 202, 202, 0.4));
    color: var(--primary-color);
    top: -20px;
    left: 25px;
}

/* DARK MODE */

div.dark div.pricetracker-fullpage .container .subscribe-bell {
    color: var(--primary-light);
}

div.dark div.pricetracker-fullpage .pricetracker-mytrackers-info button svg {
    color: white;
}

div.dark div.pricetracker-fullpage .pricetracker-mytrackers-info button.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover{
    background-color: rgb(48, 48, 48)
}


.dark .priceTrackerMenu [class*="MuiMenu-list"] li:hover{
    background-color: rgb(0, 0, 0);
}

.recharts-tooltip-label{
    color:  black;
}


