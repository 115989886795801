/* features */

@media (max-width: 800px) {

    div.features div.features-row{
        flex-direction: column;
        width:100% !important;
     }
}

div.features{
   display:flex;
   flex-direction: column;
   align-items: center;
}

 div.features div.features-row{
    display: flex;
    width: 85%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
 }