@media (max-width: 700px) {
    div.article{
        margin: 5px !important;
    }
}
 
 div.article{
    display: flex;
    flex-direction: column;
      max-width: 750px;
    margin: 50px;
 }


 div.article .article-title{
    color: var(--primary-text);
    font-weight: 600;
}

div.article .post{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

div.article .published{
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    color: var(--secondary-color)
    /* font-size: small; */
}

div.dark div.article .published{
    color: #6f97c1
}



div.article figure{
  display: flex;
    flex-direction: column;
    margin: unset;
     align-items: center;
}

div.article img{
    width: 100%;
    height: auto;
}

div.article a{
 color:var(--primary-dark);
}



div.article div.post figure{
 display:flex;
 flex-direction:column;
 align-items:center;
  margin-top:15px;
 margin-bottom:15px;
}

div.article div.post figure figcaption {
    text-align: center;
   }

div.blog .reading-progress{
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    height: 6px;
    /*  width: 100%;
    height: 10px; */
    position: fixed;
    top:0px;
    z-index: 1; 
}

div.article pre{
    font-size: initial;
    font-style: italic;
}

div.article iframe{
    width: 100%;
        height: 280px;
}

div.wp-block-embed__wrapper{
    display: flex;
    width: 100%;
}



div.blog .up-floating-icon{
    position: sticky;
     color: white;
    bottom: 70px;
    left:77%;
}

