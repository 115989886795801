/* home */

@keyframes openSidebar {
    from {left:-150px}
    to {left: 0px}
  }

@media (max-width: 700px) {
    div.home div.sidebar{
        display: none ;
        margin-left: 2px !important;
        margin-top: 0px !important;
        height: 100%;
        animation: openSidebar 0.2s ease-out;
    }

    div.home div.sidebar-toggle{
        display: flex !important;
    }

    div.home{
        flex-direction:column;
    }
    
}
@media (min-width: 700px) {
    div.home div.sidebar{
        position: relative !important
    }

}

div.home{
    display: flex;
    flex: 1;
}

    
div.home div.sidebar{
       /* display: flex; */
    flex: 1 1;
    max-width: 180px;
    /* margin-top: 25px; */
    margin-bottom: 25px;
    flex-direction: column;
    
    padding: 20px;
    background: white;
    margin-left: 25px;
    z-index: 1;
    /* border-radius: 10px;*/
}

div.dark div.home div.sidebar{
    background: black;
}

div.home div.sidebar-sticky-content{
    position: sticky !important;
    height: 50vh;
    top: 0; 
}

div.home div.sidebar-toggle{
    display: none;
    z-index: 2;
        padding-left: 15px;
    margin: 5px;
    /* font-size: xx-large; */
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
}

div.home .sidebar-section{
    color: var(--secondary-color);
    font-weight: 500;
}

div.dark div.home .sidebar-section{
    color: #6f97c1
}

div.home button.sidebar-button{
    display: flex;
    margin-left: 20px;
    justify-content: flex-start;
    color: var(--primary-text-light);
    align-items: center;
    width: 90%;
}

div.home button.sidebar-button:hover{
    background-color: rgb(223, 223, 223);
    /* color: white; */
}

div.dark div.home button.sidebar-button:hover{
    background-color:#232323 ;
}

div.dark div.home button.sidebar-button{
    color: #ffffff
}


div.home button.sidebar-nestedButton{
    display: flex;
    margin-left: 20px;
    justify-content: flex-start;
    color: var(--primary-text-light);
    flex:1;

    animation: .2s ease-out slideDown;

}

div.home button.sidebar-nestedButton{
color:var(--primary-text)
}

div.dark div.home button.sidebar-nestedButton{
color:white;
}

div.home button.sidebar-nestedButton:hover{
    background-color: rgb(223, 223, 223);
}

div.dark div.home button.sidebar-nestedButton:hover{
    background-color: rgb(43 43 43);
}



@keyframes slideDown {
    0% {
       
        transform: translateY(-20px);
    } 
    100% {
        
        transform: translateY(0);
    }
}


div.home div.sidebar-dataList{
    display: flex;
    margin-left: 25px;
    
}

.active{
    background-color: var(--secondary-color) !important;
    color: white !important;
}