@media (max-width: 670px) {
    div.newcard{
        flex-direction: column;
        align-items: center;
    }
    div.cardPreview{

        margin-left:0px;
     
    }
    
}

div.newcard{
    display: flex;
    margin: 10px;
}

label.newCard-error{
    color: red;
}

p.newCard-success{
    display: flex;
    justify-content: center;
    visibility: hidden;
    
    background-color: rgb(84, 84, 84);
    color: white;
    border-radius: 6px;
    padding: 3px;
    transition:  visibility .2s;

}
.newcard--form{
    display: flex;
    row-gap: 10px;
    flex-direction: column;
}

.newcard--input{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 250px;
}

.newCard-form-imageInput{
    border: none;
    padding:0px;
    height: 25px;
    display: block;
}

.cardPreview{
    display: flex;
    height: 350px;
    margin-left:40px;
    flex-grow: 1;
}

button.newCard-AddCard{
    font-size: large;
}