


div.imageCard {
    display: flex;
    flex: none;
    flex-direction: column;
    width: 240px;
    height: 350px;
    position: relative;
    box-shadow: 5px 5px 10px  rgba(36, 36, 36, 0.315);
}

div.imageCard:hover .imageCard--info {
  opacity: 1;
  transition: 0.5s;
  visibility: visible;
}
.imageCard:hover .imageCard--delete{
  opacity: 1;
  transition: 0.5s;
  visibility: visible;
}

.imageCard:hover{
  box-shadow: 10px 10px 15px rgba(36, 36, 36, 0.315);
}

img.imageCard--info-img{
    height: 100%;
    object-fit: cover;
    margin-bottom: 62px;
 }

 .imageCard--info {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 17%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-shadow: -1px 0 black, 0 1px black, 2px 0 black, 0 -1px black;
    
    color: rgb(255, 255, 255);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
 }
 
 span.imageCard--delete{
   display: flex;
   /* text-shadow: -1px 0 black, 0 1px black, 2px 0 black, 0 -1px black; */
   position:absolute;
   visibility: hidden;
   opacity: 0;
   right: 0;
   color: rgb(0, 0, 0);
   margin: 3px;
   /* border: 1px solid black; */
   box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
   background-color: rgb(255, 255, 255);
   padding: 6px;
   
 }

 span.imageCard--stats { 
     margin-right: 10px;
     margin-left: 10px;
     display: flex;
     align-items: center;
    }
 
  img.imageCard--profile--img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
    margin-right: 15px;
  }

  .imageCard--profile {
    background-color: #f4f7ff;
    
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    justify-content: space-between;
    
 }

 div.dark .imageCard--profile {
  background-color: rgb(31, 31, 31);
 }

 .imageCard--profile--text{
    margin-right: auto;
 }

  h2.imageCard--profile--text--h2 {
   color: #334454;
   white-space: nowrap;
   font-size: 1rem;
   margin-bottom: 2px;
   overflow: hidden;
   max-width: 150px;
  }

  div.dark h2.imageCard--profile--text--h2 {
    color: var(--secondary-light);
  }

  p.imageCard--profile--author {
   color: #a1b2bc;
   white-space: nowrap;
   overflow: hidden;
   max-width: 150px;
   font-size: 0.8rem;
   margin-top: 3px;
  }

  
 

