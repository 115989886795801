
/* hero */

@media (max-width: 800px) {

    div.hero {
        flex-direction: column;
    }
    div.hero img{
        order:1;
        width: 90% !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
        margin-top: 10px ;
        margin-bottom: 10px ;
    }

    div.landingPage div.hero img{
        width: 50% !important;
    }

    div.landingPage div.hero div.text-area .dynamic-title-wrap{
        margin:0px;
        margin-bottom: 30px;
        margin-top:10px;
    }

    div.landingPage div.text-area .title{
        align-self: center;
        margin:0px;
        justify-content: start;
        min-width: 130px;

    }

    .landingmodal-row{
        flex-direction: column !important;
    }
    form.contact-modal-form{
        margin:0px !important;
        width: -webkit-fill-available !important;
    }
    .contact-modal-image{
        
        margin:20px  0px 20px 0px !important;
    }

    div.hero .dynamicText {
        
        
    }

    div.hero div.text-area{
        order: 2;
        width: 100% !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
        margin-top: 10px ;
        margin-bottom: 10px ;
    }
}


div.hero {
    display:  flex;
    justify-content: center;
    align-items: center;
   
}

div.hero img {
   width: 50%;
    height: auto;
    min-width: 250px;
    max-width: 400px;
    margin-right: 10px;
    margin-left: 10px;
    
}

div.landingPage div.hero img{
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    transform: translatey(0px);
	animation: float 5s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

div.landingPage div.hero div.text-area .dynamic-title-wrap{
    display: flex;
    flex-direction: row;
}

div.landingPage div.text-area .title{
    color: black;
    white-space: nowrap;
  overflow: hidden;
}

div.hero .dynamicText {
    justify-content: baseline;
    flex-grow: 1;
    display: flex;
}

div.hero .dynamicText input{
    background-color: transparent;
    padding: 0px;
    min-width: 210px;
    padding-top:9px;
    width: fit-content;
    height: auto;
    border: none;
}

div.hero .dynamicText input::placeholder{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: var(--secondary-light);
    font-weight: 500;
    font-size: x-large;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

div.contact-modal{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    white-space:pre-line;
    border: 1px solid var(--primary-color);
    box-shadow: 0 2.5rem 2rem -2rem hsl(200 50% 20% / 40%);
    background-color: white;
    border-radius: 5px;
    padding: 20px;

    animation-name: zoom-in-appear;
    animation-duration: 0.1s;
}

/* div.contact-modal.dark{
    background-color: black;
} */

div.landingmodal-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact-modal-image{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:30px;
}

.contact-modal-image img{
    width: auto;
    height: 200px;
}

form.contact-modal-form{
    display: flex;
    width: 250px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin:30px;
}

div.text-area{
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

div.text-area .title{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
        color: var(--secondary-color);
    /* font-weight: bolder; */
    /* font-size: larger; */
    margin: 10px;
    
}


div.text-area .text{
    display: flex;
    flex-wrap: wrap;
    white-space: pre-line;
    min-width: 250px;
    margin: 10px;
}

div.hero div.text-area .link{
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px !important;
    color: var(--primary-dark);
    margin: 10px;
}

div.hero .hero-button{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

