
/* projects */


div.projects{
    display:flex;
    flex-direction: column;
        width: 100%;
    align-items: center;
}

div.projects .infinityCards{
    display: flex;
    flex-direction: column;
    width: 85%;
}

div.projects .blogIntegration{
    display: flex;
    flex-direction: column;
    width: 85%;
}

div.projects div.text-area{
    flex:auto !important;
    width:50%;
    max-width: 650px;
    margin-left: 30px;
    margin-right: 30px;
}

div.projects div.text-area .text {
    min-width: auto !important;
}