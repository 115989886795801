
@media (max-width: 700px) {
    
    div.blog{
        flex-direction: column;
        margin-top:30px
    }
    div.blog-skeleton{
        flex-direction: column;
    }

    div.blog-posts{
        width: auto !important;
        min-width:unset !important;
        max-width: unset !important;
        margin: 5px !important;
    }
    div.blog-posts-skeleton{
        width: auto !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
        min-width:unset !important;
        max-width: unset !important;
    }

    div.blog-mainArticle{
        display: flex;
        justify-content: flex-start;
        width: unset !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
    div.blog-mainArticle-skeleton{
        width: auto !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
        min-width: unset !important;
        max-width: unset !important;
    }
}

div.blog-root{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}


div.blog{
    display: flex;
    width: 100%;
}
div.blog-skeleton{
    display: flex;
    height: 100%;
    justify-content:space-between;
    width: 100%;
}

div.blog-mainArticle{
    display: flex;
    justify-content: center;
    margin-left: 30px;
    margin-right: 30px;
    width:70%;
}

div.blog-mainArticle-skeleton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin-left: 80px;
    margin-right:80px;
    width:70%;
}

div.blog-article-skeleton{
    max-width: 750px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    
}


div.blog-posts{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    margin: 20px;
}

div.blog-posts-skeleton{
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 20px;
}

.blog-posts-title{
    display: flex;
    padding-left: 10px;
    color:var(--primary-text);
    align-self: self-start ;
}

div.separator{
    border-bottom: 1px solid;
    margin: 20px;
}

span.MuiSkeleton-root{
    background-color: lightgray;
}

div.dark span.MuiSkeleton-root{
    background-color: rgb(67, 67, 67);
}

div.dark #blog-load-more{
    color: var(--secondary-light);
    border-color: var(--secondary-light);
}
    

/* Deprecated - just for reference */

/* .loading-circle{
    display: flex;
    
    justify-content: center;
    width: 100%;
    align-items: center;
} */


