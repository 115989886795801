/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  div.timeline img{
    
    border-radius: 3px;
    width: 100%;
    height: auto;
}
  
  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #747474fc;
    top: 0;
    bottom: 0;
 
    margin-left: -1px;
  }
  
  /* Container around content */
  div.timelinePost .container {
    padding: 2px 20px;
    margin-top:4px;
    cursor:pointer;
    position: relative;
    background-color: inherit;
    
  }
  
  /* The circles on the timeline */
  div.timelinePost .container::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    right: -17px;
    background-color: white;
    border: 4px solid var(--primary-color);
    top: 17px;
    border-radius: 50%;
    z-index: 1;
  }
  
  /* Place the container to the left */
  .left {
    left: 0;
  }
  
  /* Place the container to the right */
  .right {
  
  }
  
  /* Add arrows to the left container (pointing right) */
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
  }
  
  /* Add arrows to the right container (pointing left) */
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 15px;
    width: 0;
    z-index: 1;
    left: 11px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    transition: all 0.3s;
    border-color: transparent rgb(240 240 240) transparent transparent;
  }

  .right_selected::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 15px;
    width: 0;
    z-index: 1;
    left: 11px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent var(--primary-color) transparent transparent;
  }
  
  /* Fix the circle for containers on the right side */
  .right::after {
    left: -8px;
  }
  
  /* The actual content */
  .content {
    padding: 10px 20px;
    background-color: rgb(240 240 240);
    position: relative;
    border-radius: 6px;
    
  }

  .selected{
    background-color: var(--primary-color);
    color:white !important;
    box-shadow:var(--primary-color) 2px 2px 10px;
    transition: all 0.3s;
    border: 0px;
  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 700px) {
    /* Place the timelime to the left */
    .timeline::after {
    left: 31px;
      
    }

    .timeline {
         width: 100%;
      margin: 0 15px !important;
    }
    
    /* Full-width containers */
    div.timelinePost .container {
  
    padding-left: 60px;
    padding-right: 20px;
    }
    
    /* Make sure that all arrows are pointing leftwards */
    div.timelinePost .container::before {
    left: 60px;
   
    }
  
    /* Make sure all circles are at the same spot */
    .left::after, .right::after {
    left: 22px;
    }
    
    /* Make all right containers behave like the left ones */
    .right {
    left: 0%;
    }
  }