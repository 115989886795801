

feature{
    display: flex;
    font-size: large;
    flex-direction: column;
    background-color: var(--primary-color);
    padding-left: 60px;
    padding-right: 60px;
    color:white;
}

div.dark feature {  
    background-color: black;
}

.cards-list {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    
   
    padding-bottom: 20px;
}

feature > h1{
    margin-top:20px
}

div.board{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding-bottom: 20px;
}

.removed-item {
    animation: removed-item-animation .2s cubic-bezier(.55,-0.04,.91,.94) forwards;
}
@keyframes removed-item-animation {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0);
    }
}