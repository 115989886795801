
/* login */

h1{
    font-size: 2em;
    margin-top: 0px;
    margin-bottom: 0.8em;
    font-weight: bold;
}

.login-form{
    display: flex;
    flex-direction: column;
    margin: auto;
    width:300px;
}

/* span.login-icon{
    padding: 5px;
} */


.login-form-inputs{
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    
}

.login-form-input-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 100%; */
    color: rgb(255, 255, 255);
    
}

.login-form-input-checkbox{
    color: #6e6e6e;
}

.login-form-input-row-inputanderror{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 2px;
}

input{
    display: flex;
    flex-grow: 1;
    padding-left: 5px;
}

label.error{
    color: red;
    margin-left: 51px;
}

button.login{
    /* margin-top: 1em; */
    height: 2em;
    font-size: large;
}

.login-loading-circle{
    display: flex;
    
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    margin-top: 18px;
}
