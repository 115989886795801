
/* 404 */

@media (max-width: 800px) {

    div.notFound {
        flex-direction: column;
     }

     .notFound-textArea{
        margin-top: 30px;
        margin-left: 15px !important;
    }
}

div.notFound {
    display: flex;
    align-items: center;
    justify-content: center;
}

.notFound-textArea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
}

.notFound-title{
    font-weight: 900 !important;
    color: var(--secondary-dark);
    margin-bottom: 10px !important;
}

div.notFound img{
    max-width:200px;
    min-width: 100px;
    height: auto;
}

.doggy{
    
    display: flex;
    width: 12px;
    min-width: 10px !important;
    position: relative;
}


