/* navbar */
/* The animation code */
@keyframes openMenu {
    from {top:-150px}
    to {top: 56px}
  }

  @keyframes closeMenu {
    from {top:56px}
    to {top: -150px}
  }


@media (max-width: 850px) {
    .nav-button{
        height:45px;
        width:75px !important;
    }
    .nav-button-login{
        height:45px;
        width:58px !important;
    }
    
    ul.nav-links{
        display: none;
    }

    div.sign-buttons{
        flex:1;
        justify-content: flex-end;
        /* display: none;
        width: 200px; */
    }

    nav .toggler{
        display: none;
    }
    
    li{
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width: 850px) {
    div.hamb-menu{
        display: none !important;
    }
    div.navbar .hamb-menu-icon{
        display: none !important;
    }
}


div.navbar .MuiTooltip-tooltip{
    background-color: var(--primary-dark) !important;
    border: 1px solid white;
}

.MuiTooltip-arrow{
    color: var(--primary-dark) !important;
}

.MuiSwitch-thumb{
    background-color: var(--primary-text) !important;
}

.MuiSwitch-thumb:hover{
    background-color: var(--primary-light) !important;
}


span.hamb-menu {
    display: none;
    padding:10px;
    cursor: pointer;
}

span.hamb-menu i.bi{
    font-size: x-large;
}

/* Hamburger Menu flexed */
div.hamb-menu{
    display: none;
    z-index: 3;
    margin: 6px;
    position: absolute;
    align-items: center;
    width: -webkit-fill-available;
    border-radius: 10px;
    padding-left: 35px;
    padding-right: 35px;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: openMenu ease-in-out 0.2s;
    /* animation: ease-in-out 2.2s; */
    /* animation-direction: alternate; */
}

 .closeMenu{
    animation-name: closeMenu !important;
    
}

 div.nav-links-hamb{
    display:flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
    list-style-type: none;
    color: inherit;
}

div.nav-links-hamb li .bt-nav-link:hover {
    background-color:var(--primary-color);
}

div.nav-links-hamb a.nav-link:hover{
    color: white;
}

 div.sign-buttons-hamb{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

div.navbar .hamb-menu-icon{
    font-size: 40px;
    padding-left: 5px;
    cursor:pointer;
    
}

div.navbar .hamb-menu-icon div{
    width: 35px;
    height: 3px;
    margin: 6px 0;
    user-select: none;
    transition: 0.2s;
    background-color: rgb(43, 40, 58);
    
}

div.dark div.navbar .hamb-menu-icon div{
    background-color: rgb(255, 255, 255);
}

.openHambMenu .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}

.openHambMenu .bar2 {
    opacity: 0;
}

.openHambMenu .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -8px);
}
div.navbar{
    
}

nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position:relative;
  
    color: #2B283A;
       padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color:white;
    z-index: 4;
}

div.navbar .nav-brand{
    
    color: var(--secondary-color);
    font-weight:bolder !important;
    font-size: 25px;
    text-decoration: none;
    padding: 0.5rem;
    
    transition: all .2s ease-in-out;
    /* border: 1px solid transparent; */
}

div.navbar .nav-brand-img{
    transition: all .2s ease-in-out;
    max-height: 65px;
    max-width: 135px;
    cursor: pointer;
}

div.dark div.navbar .nav-brand-img {
    filter: brightness(2);
}

div.navbar .nav-brand-img:hover{
    /* box-shadow: rgba(0, 0, 0, 0.308) 0px 8px 13px; */
    text-shadow: -1px 2px 2px rgba(79, 78, 78, 0.3);
    
    transform: translate3d(0px, -1px, 0px);
    border-radius: 6px;
}

.nav-link{
    text-decoration: none;
    color: var(--primary-text);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

Button.bt-nav-link{
    padding: 0px;
    
    
}
Button.bt-nav-link:hover{
    background: var(--primary-color);
    color: white !important;
}
Button.bt-nav-link:hover a{
    color: white !important;
}

div.dark Button.bt-nav-link:hover{
    background-color: #373636 !important;
    color: white !important;
}

Button.bt-nav-link .nav-link:hover{
    color: white !important;
}


.nav-link:hover{
    text-decoration: none;
    
}

.nav-link-active{
    text-decoration: none;
    color: white;
    background-color: var(--primary-color);
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
}

.nav-link-active:hover{
    text-decoration: none;
    background-color: var(--primary-dark);
    color:white;
}

.bt-nav-link-dropdown{
    padding: 5px !important;
    border-radius: 4px;
    height: 34.5px;
    color: var(--primary-text) !important;
}
div.dark .bt-nav-link-dropdown{

    color: white !important;
}

.bt-nav-link-dropdown:hover{
    background-color: rgb(223, 223, 223) !important;
    
}

div.dark .bt-nav-link-dropdown:hover{
    color: white !important;
    background-color: #282828!important;
}

.nav-links{
    padding-left: 2em;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    margin-right: auto;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.sign-buttons{
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    flex-direction: row;
    align-content: center;
}

.nav-button{
    width: 78px;
    min-width: auto !important;
    padding-right: 20px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    padding-left: 20px !important;
}

.nav-button-login{
    width: 70px;
    min-width: auto !important;
    padding-right: 20px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    padding-left: 20px !important;
}


label.nav-username{
    margin-right: 10px;
    margin-left: 10px;
}

.nav-profilepicture{
    height: 40px;
    width: 40px;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.nav-profilepicture-logout{
    height: 40px;
    width: 40px;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    filter: hue-rotate(var(--deg));
}



 .profile-name{
    display: flex;
    margin:10px !important;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--primary-color)
}

.dark .profile-name{
    color: var(--primary-light);
}

.profile-button{
    display: flex !important;
    width: 90%;
    margin: auto !important;
    justify-content: center !important;
}

.profile-option{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    margin-bottom: 5px !important;
    
}

div.navbar li{
    padding-left: 5px;
    padding-right: 5px;
    color: inherit;
}

.toggler {
    display: flex;
    cursor: pointer;
    animation: 0.5s ease-in-out 0s 1 appear;
}

.toggler :hover{
    font-weight: 900;
    border-radius: 50%;
}

div.navbar .MuiSvgIcon-root{
    margin:unset !important;
    padding: 8px !important;
}

div.navbar .MuiSvgIcon-root:hover{
    color:var(--primary-dark);
}

div.dark div.navbar .MuiSvgIcon-root{
    color:white;
}
div.dark div.navbar .MuiSvgIcon-root:hover{
    
}

.navbar-paper-projects-list #css-paper-list-icon .MuiListItemIcon-root {
    display: flex;
    justify-content: flex-end;
}

.dark #css-paper-list-icon .MuiListItemIcon-root {
    color: white !important;
}


.dark.navbar svg:hover {
    color: var(--secondary-light);
}

.dark [class*="MuiPaper-root"] {
    background-color: rgb(20, 20, 20);
    color: white
}

.dark .navbar-paper-projects-list [class*="MuiPaper-root"] li:hover{
    background-color: rgb(0, 0, 0);
} 

.dark #profile-menu [class*="MuiList-root"] li:hover{
    background-color: rgb(0, 0, 0);
}

.dark .MuiListItemIcon-root{
    color: white !important;
}

@keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }






