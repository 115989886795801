
/* sectionHeader */

@media (max-width: 600px) {

    div.sectionHeader {
        width: 90% !important;
    }
    div.sectionHeader .title{
        font-size: 2rem;
            margin-right: 0px !important;
    margin-left: 0px !important;
    }
}

div.sectionHeader {
    display:  flex;
    flex-direction: column;
    justify-content: center;
    white-space: pre-line;
    max-width: 750px;
    width: 60%;
    margin-top: 20px;
    margin-bottom: 20px;
}

div.sectionHeader .title{
    display: flex;
    justify-content: center;
    color: var(--primary-text);
    flex-wrap: wrap;
    font-weight: bolder;
    margin: 40px;
}

div.sectionHeader .text{
    display: flex;
    justify-content: center;
    text-align: justify;
    flex-wrap: wrap;
    margin: 20px;
}


