
/* about */

@media (max-width: 900px) {
    
    div.webScrap .filter-row{
        flex-direction: column;
        align-items: center !important;
        width: 100% !important
    }

    div.webScrap .filter-full{
        margin-top: 40px;
        order: 1;
        justify-content: center;
        width: 100% !important
    }
    
}

div.webScrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.webScrap-product{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.webScrap-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;

}

div.webScrap .filter-row{
    display: flex;
    width: 60%;
    align-items: baseline;
    justify-content: space-evenly;
}

div.webScrap .filter-label{
    margin-right: 30px;
}

div.webScrap .filter-full{
    display: flex;
    align-items: baseline;
}

