
h1{
    font-size: 2em;
    margin-top: 0px;
    margin-bottom: 0.8em;
    font-weight: bold;
}

.register-form{
    display: flex;
    flex-direction: column;
    margin: auto;
    
    width:300px;
}


.register-form-inputs{
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    
}


.register-form-input-row{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    
    color: rgb(255, 255, 255);
}

.register-form-input-row-inputanderror{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 2px;
}

i.registerLoginForms{
    margin:0px;
    margin-right: 10px;
    padding: 12px;
    background-color: #6f6f6f;
    border-radius: 2px;
}

/* span.register-icon{
    padding: 5px;
} */

input{
    display: flex;
    flex-grow: 1;
    height: 30px;
}

label.error{
    color: red;
}

button.register{
    margin-top: 1em;
    height: 2em;
    font-size: large;
}

