
/* webkit */

.cards-list::-webkit-scrollbar {
    height: 0.7em;
}
.cards-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(209, 209, 209, 0.3);
}
.cards-list::-webkit-scrollbar-thumb {
  background-color: rgb(224, 224, 224);
  outline: 1px solid rgb(214, 214, 214);
  border-radius: 10px;
}

img {  
    -webkit-user-drag: none;  
    user-select: none;
}

input {
    border: 1px solid grey;
    outline: none;
}

/* Colors */

:root {
    
    --primary-light: #59887bfc;
    --primary-color: #3b5451;
    --primary-dark: #1d2c2a;
    /* --primary-color: #7986cb; */
    /* --primary-dark: #47589b; */
    


    --background-color: #f17e5b; 

    --secondary-light: #7192d5;
    --secondary-color: #3e556d;
    --secondary-dark: #1d2e3f;
    /* --secondary-color: #f17e5b;   */
    /* --secondary-dark: #c63f17; */
    


    /* --primary-light: #AEBDCA;
    --primary-color: #7895B2;
    --primary-dark: #426282;

    --background-color: #e8c87b; 

    --secondary-light: #f0dec3;
    --secondary-color: #e8c87b; 
    --secondary-dark: #cfac5b;  */

    /* --primary-light: #dcecde;
    --primary-color: #94B49F;
    --primary-dark: #577863;

    --background-color: #ECB390; 

    --secondary-light: #FCF8E8;
    --secondary-color: #ECB390; 
    --secondary-dark: #d3753a;  */

    --primary-text: #6e6e6e;
    --primary-text-light: #656565;
    --secondary-text: #757575;
}

*{
    -webkit-tap-highlight-color: transparent;
}


html{
    /* overflow-y: scroll;
    overflow-x: hidden ; */
}

body{
    margin:0px;

    
}

.root{
  
}

.website{
    min-height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

/*** Dark Mode ***/
body.dark{
    background-color: #000000;
}

div.dark div.sectionHeader .title {
    color: var(--secondary-light);
}

div.dark div.text-area .title{
    color: var(--secondary-light) !important;
}

div.dark div.article .article-title{
    color: white !important;
}

div.dark div.article a{
 color:var(--primary-color);
}

div.dark .content{
    color: #2d2d2d;
}

div.dark .blog-posts-title{
    color: var(--secondary-light) !important;
}

div.dark div.text-area .link{
    color: var(--primary-color) !important;
}

.website.dark {
    background-color: #000000;
    color: var(--secondary-light)
}
/* Navbar */
nav.dark {
    background-color: #000000;
    color: var(--secondary-light);
}

div.dark div.navbar .nav-brand:hover{
    color:var(--secondary-color) !important;
    border-radius: 6px;
}

div.dark div.navbar .nav-brand{
    color:var(--secondary-light) !important;
    border-radius: 6px;
}



body.dark div.nav-links-hamb li button a {
    color: var(--primary-light)
}

body.dark div.nav-links-hamb li button a:hover {
    color: var(--primary-dark);
}

body.dark div.nav-links-hamb  a.nav-link-active {
    color: var(--primary-dark)
}

div.navbar .bt-nav-link.dark .nav-link{
    color: var(--primary-light)
}
div.dark div.navbar .bt-nav-link.dark .nav-link{
    color: white;
}



div.dark .bt-nav-link.dark .nav-link-active{
    background-color: var(--primary-color);
    color: #fffffffc;
}
div.navbar .bt-nav-link.dark:hover{
    background-color: var(--primary-color);
    border-radius: 3px;
}

div.navbar div.sign-buttons .nav-button.dark{
    color: var(--primary-light);
    /* border-color: var(--primary-light); */
    
}

body.dark div.navbar .hamb-menu-icon div{
    background-color: white;
}

/* input dark mode */

div.dark .login-form {
    background-color: #ffffff;
    color: var(--primary-text);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(158, 158, 158, 0.692), 0 3px 6px rgba(146, 146, 146, 0.699);
}

div.dark .register-form {
    background-color: #ffffff;
    color: var(--primary-text);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(158, 158, 158, 0.692), 0 3px 6px rgba(146, 146, 146, 0.699);
}

/* div.dark .MuiInputBase-root {
    background-color: white;
    padding-left:10px;
}

div.dark label.MuiFormLabel-root{
    color: rgb(0, 0, 0);
    padding-left:10px;
    z-index: 23123;
}
div.dark .MuiInputLabel-shrink{
    color: var(--primary-light) !important;
    padding-left:0px !important;
} */

div.hamb-menu.dark{
    background-color: rgb(20, 20, 20);
}

/* Toggler */

body.dark .toggler--slider {
    border-color: white;
    background-color: #F5F5F5;
}

body.dark .toggler--slider {
    justify-content: flex-end;
}

body.dark .toggler--slider--circle {
    background-color: black;
}

div.dark .background{
    background-color: black;
}

div.dark{
    
    color: white;    
}

/* Infinity Cards */
feature.dark {
    background-color: #000000;
    color: white;    
}

.card--profile.dark{
    background-color: #f1f1f1;
} 

/* Price tracker */
div.dark .css-kv8t8y-MuiTypography-root-MuiLink-root {
    color: var(--primary-light);
}

/* Home */
div.sidebar.dark{
    background-color: #000000!important;
    color: white;  
}

/* Footer */
div.dark .footer-brand {
    color: var(--primary-light);    
}

div.dark .css-1358sf3-MuiTypography-root-MuiLink-root{
    color: var(--primary-light);
}
