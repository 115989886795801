

@media (max-width: 1100px) {
   div.product-table{

      width: 80% !important;
      
   }
}

/* Mobile */
@media (max-width: 900px) {
   div.product-table{
      width: 100% !important;
   }

   div.product-table .product-row{
      margin: 5px;
   }

   div.product-table .product-mobile-bundle{
      flex-direction: column;
   }
   img.table-product-img{
      width: 80px;
      height: auto;
         
      
   }
   
   div.product-table .product-title{
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.5em !important;

      word-wrap: break-word;
      max-height: 2.5em;
      line-height: 1em;
      
   }
   div.product-table .product-column{
      padding: 0.2em !important;
   }
}

div.table{
   display: flex;
   justify-content: center;
}

div.product-table{
   display: flex;
   flex-direction: column;
       max-width: 900px;
   
}

div.product-table .product-row{
   display: flex;
   align-items: center;
}

div.product-table .product-mobile-bundle{
   display: flex;
   align-items: center;
}


div.product-table .product-column{
   display: flex;
   padding: 1em;
}

div.product-table .product-title{
   display: flex;
   padding: 1em;
}


img.table-product-img{
   max-width: 200px;
   max-height: 100px;
    border-radius: 10px;
}

div.product-table a{
   user-select: none;
   text-decoration: none;
   border-radius: 30%;
   outline: none;
}

div.product-table a:hover{
   text-decoration: none !important;
   
   outline: none;
}

div.product-table a .MuiChip-root{
   cursor: pointer;
}

div.product-table a .MuiChip-root:hover{
   cursor: pointer;
   background-color: var(--primary-dark);
   box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
   transform: translateY(-1px);
}
/*

a:hover .MuiChip-label {
   user-select: none;
   text-decoration: none !important;
}

a:hover .MuiChip-label span{
   user-select: none;
   text-decoration: none !important;
}

a .MuiChip-label{
   user-select: none ;
   text-decoration: none;
} */




